import styled from 'styled-components';

import { TEXT_INDENT } from 'styles/typography';

const Content = styled.div`
  p:first-of-type {
    text-indent: ${({ textIndent }) => (textIndent ? TEXT_INDENT : 0)};
  }
`;

function sanitizeHtml(html) {
  if (!html) return '';

  return html.replace('<p></p>', '');
}

const RichText = ({ html, ...props }) => (
  <Content
    dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}
    {...props}
  />
);

export default RichText;
