import Image from 'next/legacy/image';
import styled from 'styled-components';

import BREAKPOINTS from 'styles/breakpoints';
import COLORS from 'styles/colors';
import Link from 'next/link';
import TYPO, { H3, H5 } from 'styles/typography';
import { formatEnumToString, formatSlug } from 'utils/helpers';

const TransitionTime = '0.2s';

// === Section Styles ===

const Section = styled.section`
  padding: 110px 0;
  color: ${COLORS.forest};
  background-color: #e9f4ef;
  ${BREAKPOINTS.small`
    padding: 125px 0 150px;
  `}
`;

const SectionHeading = styled(H3)`
  margin-bottom: 65px;
  text-align: center;
  ${BREAKPOINTS.small`
    margin-bottom: 100px;
  `}
`;

const Cards = styled.div`
  padding: 0 25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 32px;
  column-gap: 58px;
`;

// === Card Styles ===

const LocationCard = styled.div`
  text-decoration: none;
  color: inherit;
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 350px;
  cursor: pointer;
  height: 100%;

  transition: all ${TransitionTime} ease-in-out;

  &:hover {
    background-color: #fe5b44;
    color: inherit;
  }
`;

const CardPadding = '24px';

const CardHeading = styled(H5)`
  padding: ${CardPadding};
  font-size: 1.875rem;
  font-weight: 700;
`;

const CardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${CardPadding} 32px ${CardPadding};
  margin-bottom: auto;
`;

// === Column Styles ===

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ColumnHeading = styled.h4`
  font-size: 1.125rem;
  font-weight: 700;
`;

const ColumnText = styled.span`
  color: ${COLORS.darkGrey};
  ${TYPO.poppins}
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  transition: color ${TransitionTime} ease-in-out;

  ${LocationCard}:hover & {
    color: ${COLORS.white};
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
`;

const StationInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StationIcon = styled.img`
  height: 1.2rem;
  width: 1.2rem;
  transition: filter ${TransitionTime} ease-in-out;

  ${LocationCard}:hover & {
    filter: brightness(0) saturate(100%) invert(1);
  }
`;

// === Arch Styles ===

const Arch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 381px;
  position: relative;
  border-radius: 50% 50% 0 0;
  overflow: hidden;
  color: white;
  background-color: ${COLORS.forest};
`;

const ArrowSize = '2rem';

const Arrow = styled.img`
  position: absolute;
  top: 1rem;
  height: ${ArrowSize};
  width: ${ArrowSize};
  opacity: 0;
  transition: opacity ${TransitionTime} ease-in-out;
  z-index: 10;

  ${LocationCard}:hover & {
    opacity: 1;
  }
`;

const FindUsInfo = ({ address }) => {
  const { addressLine1, addressLine2, city, postcode } = address;

  const hasAddress = addressLine1 && postcode && city;

  return (
    hasAddress && (
      <Column>
        <ColumnHeading>Find Us</ColumnHeading>
        <Address>
          {addressLine1 && <ColumnText>{addressLine1}</ColumnText>}
          {addressLine2 && <ColumnText>{addressLine2}</ColumnText>}
          {city && <ColumnText>{city}</ColumnText>}
          {postcode && <ColumnText>{postcode}</ColumnText>}
        </Address>
      </Column>
    )
  );
};

const NearestStationInfo = ({ nearestStation }) => (
  <Column>
    <ColumnHeading>Nearest Station</ColumnHeading>
    <StationInfo>
      <StationIcon
        src="/images/icons/tube-station-icon.svg"
        alt="Station icon"
      />
      <ColumnText>{nearestStation}</ColumnText>
    </StationInfo>
  </Column>
);

const renderCard = (venue) => {
  const {
    id,
    venueLocation,
    addressLine1,
    addressLine2,
    city,
    postcode,
    venueImage,
    nearestStation,
  } = venue;

  const cardContent = (
    <LocationCard key={id}>
      <CardHeading small>{formatEnumToString(venueLocation)}</CardHeading>
      <CardInfo>
        <>
          <FindUsInfo
            address={{
              addressLine1,
              addressLine2,
              city,
              postcode,
            }}
          />
          {nearestStation && (
            <NearestStationInfo nearestStation={nearestStation} />
          )}
        </>
      </CardInfo>

      <Arch>
        <>
          <Arrow
            src="/images/icons/arrow-right-heavy.svg"
            alt="Arrow pointing to the right"
          />
          <Image layout="fill" objectFit="cover" src={venueImage?.url ?? ''} />
        </>
      </Arch>
    </LocationCard>
  );

  return (
    <Link href={`/${formatSlug(venueLocation)}`} key={id} passHref>
      {cardContent}
    </Link>
  );
};

const LocationCardSection = ({ heading, venues }) => {
  return (
    <Section>
      <SectionHeading>{heading}</SectionHeading>
      <Cards>{venues?.map((venue) => renderCard(venue))}</Cards>
    </Section>
  );
};

export default LocationCardSection;
